import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTag = ({ data, pageKey }) => {
    
    const metaTag = data?.data?.data?.[pageKey]?.metatag_info_data;
    const currentUrl = window.location.href;
    const cleanedUrl = currentUrl.replace(/\/$/, ''); 
    
    return (
        <>
        {pageKey == "Page_Not_Found" ? (
            <div>
               <Helmet>
                  <title>Page Not Found</title>
                  <link rel="canonical" href={cleanedUrl} />
                </Helmet>
            </div>
        ) : (
            <>
            {metaTag?.length > 0 ? (
                <div>
                    <Helmet>
                    <link rel="canonical" href={currentUrl} />
                        <title>
                            {metaTag[0]?.title !== "" ? (
                                metaTag[0]?.title
                            ) : (
                                "Ishan Technologies - Uniquely Yours"
                            )}
                        </title>
                        {metaTag[0]?.description !== "" &&
                            <meta name="description" content={metaTag[0]?.description} />
                        }
                        {metaTag[0]?.keywords !== "" &&
                            <meta name="keywords" content={metaTag[0]?.keywords} />
                        }
                    </Helmet>
                </div>
            ) : (
                <div>
                    <Helmet>
                        <title>Ishan Technologies - Uniquely Yours</title>
                    </Helmet>
                </div>
            )
            }
            </>
        )}
        </>
    )
}

export default MetaTag