import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ClientExperiences({ homeData, pageKey, tag }) {

  const [testimonials, setTestimonials] = useState([]);
  const [generalServicesData, setGenaralServicesData] = useState({});

  useEffect(() => {

    if (homeData && homeData.data && homeData.data.data && homeData.data.data?.[pageKey] && homeData.data.data?.[pageKey]?.testimonial_info_data) {
      const testimonialData = homeData.data.data?.[pageKey]?.testimonial_info_data;
      setTestimonials(testimonialData);
    } else {

      if (homeData) {

        setTestimonials(homeData);

      } else {
        setTestimonials([])
      }

    }

    if (homeData && homeData.data && homeData.data.data && homeData.data.data.general_settings && homeData.data.data.general_settings.general_settings_data) {
      const general_setting_data = homeData.data.data.general_settings.general_settings_data;

      let servide_display = null;
      general_setting_data.map((service_data_info) => {
        if (service_data_info.tag == `${tag}_testimonial`) {
          servide_display = service_data_info;
        }
      })

      if (servide_display) {
        const parsedData = JSON.parse(servide_display.response);

        setGenaralServicesData(parsedData);
      } else {
        setGenaralServicesData(null);
      }

    }

  }, [homeData])

  var settings = {
    dots: false,
    arrow: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <div className="container">

        {testimonials && testimonials.length > 0 ? (
          <>
            {generalServicesData ? (<h2>{generalServicesData?.title}</h2>) : null}
            <div className="client-wrap">
              <Slider {...settings}>

                {testimonials.map((testimonial) => (

                  <div className="item" key={testimonial.id}>
                    <div className="img">
                      <figure>
                        <img
                          src={testimonial.image}
                          alt={testimonial.altText}
                          height={269}
                          width={269}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div className="content">
                      <div className="content-inner">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: testimonial.description,
                          }}
                        />
                        <span className="quote-ic"></span>
                      </div>
                      <div className="name">
                        <span>{testimonial.name}</span>
                        <span className="position">{testimonial.role}</span>
                      </div>
                    </div>
                  </div>

                ))}
              </Slider>
            </div>
          </>

        ) : null}
      </div>
    </>
  );
}