import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import { Link } from "react-router-dom";
import Accordion from '../../components/accordion';
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import Advantage from "../../components/CommonSections/Advantage";
import CtaBanner from "../../components/CommonSections/CtaBanner";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";


export default function HighSpeedBroadbands() {
  const [pincode, setPincode] = useState('');
  const [availability, setAvailability] = useState(null);
  const [isInputOpened, setIsInputOpened] = useState(false);
  const [applyDelayedClass, setApplyDelayedClass] = useState(false);
  const checkAvailability = (enteredPincode) => {


    if (enteredPincode.length === 6) {
      const data = {
        pincode: enteredPincode
      }
      axios.post(`${API_BASE_URL}/api/pincode/checkPincode`, data).then((response) => {

        if (response.data.error === false) {
          setAvailability(response.data.message);
          setIsInputOpened(!isInputOpened);
          setTimeout(() => {
            setAvailability(null);
            setApplyDelayedClass(false);
            setPincode('');
          }, 5000);
        }
      }).catch((error) => {
        setAvailability('Something Went Wrong!');
      })

    } else {
      setAvailability('Please enter valid pincode!');
    }
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  const handleInputChange = (e) => {
    e.preventDefault();
    const enteredPincode = e.target.value;
    setPincode(enteredPincode);
    if (enteredPincode.trim() === '') {
      setAvailability(null);
      setApplyDelayedClass(false);
    } else {
      checkAvailability(enteredPincode);
    }
  };
  const toggleInputClass = () => {
    setIsInputOpened(!isInputOpened);
  };
  useEffect(() => {
    if (availability !== null) {
      const timeoutId = setTimeout(() => {
        setApplyDelayedClass(true);
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [availability]);

  useEffect(() => {
    getData();
  }, [])

  const [highSpeedServicesData, setHighSpeedServicesData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);

  const getData = () => {
    axios.post(`${API_BASE_URL}/api/highspeedservices/getHighSpeedServices`).then((response) => {

      setHighSpeedServicesData(response);

      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "high_speed_service_advantage") || null;
      const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");
      networkServicesSettingsResponse.advantages = networkServicesSettingsResponse;
      setSettingResponse(networkServicesSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }


  return (

    <>
      {highSpeedServicesData === null ? (
        <PageLoader />
      ) : (
        <>
        <SubServiceSchema title={highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_banner_data
            [0].title} url='https://ishantechnologies.com/network-services/high-speed-broadband' parentTitle='Network Services' parentUrl='https://ishantechnologies.com/network-services' />
        <MetaTag data={highSpeedServicesData} pageKey="high_speed_services"/>
          <div className="page_heading responsive_heading">
            <div className="container">
              <HeaderTitle headerTitledata={highSpeedServicesData} pageKey="high_speed_services" />
            </div>
          </div>
          <div className="video ">
            <FrontVideo homeData={highSpeedServicesData} pageKey="high_speed_services" />
            <h2 className="home-heading">{highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_banner_data
            [0].banner_title}</h2>
          </div>

          <div className="empowering section-space spin-wrap">
            <div className="container">
              <div class="breadcrumb high-speed-bread">
                <ul>
                  <li>Network services</li>
                  <li>High-speed broadband</li>
                </ul>
              </div>
              <div className="empowering-inner">
                <div className="empowering-right high-speed-information" dangerouslySetInnerHTML={{ __html: highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_information_data[0]?.description }}>
                </div>
              </div>
            </div>
          </div>
          <div className="ishan-offer gradiant-bg">
            <div className="container">
              <h2>Ishan offers great internet usage experience to all its<br></br> customers with its dependable customer service.</h2>
              <div className="offer-btns">
                <Link to="/network-services/high-speed-broadband/plans" className="button btn-red-whitebg" onClick={handleClick}>View Broadband Plans </Link>
                <div className="availability-wrap">
                  <button className="button btn-red-whitebg" onClick={toggleInputClass}>Check Availability </button>
                  <div className={`input-value ${isInputOpened ? 'opened' : ''}`}>
                    <input className="input-available" type="text" placeholder="Pin code" value={pincode} maxlength="6" onChange={handleInputChange} />
                    {availability !== null && (<span className={`availability-status ${applyDelayedClass ? 'fade-in' : ''}`}>{availability}</span>)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Advantage title={settingResponse?.advantages} data={highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_advantages_data} />

          <div className="frequently section-space">
            <div className="container">
              <h2 className="supercharge-heading">Frequently Asked Questions (FAQs)</h2>
              <Accordion items={highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_faq_data} />
            </div>
          </div>


          <div className="client-experiences section-space">
            <ClientExperiences homeData={highSpeedServicesData} pageKey="high_speed_services" tag="high_speed_broadband" />
          </div>

          <CtaBanner bannerData={highSpeedServicesData?.data?.data?.high_speed_services?.high_speed_services_cta_banner_data} />
        </>
      )}
    </>

  );
}