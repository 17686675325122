import React, { useEffect, useState, useRef } from "react";

export default function FrontVideo({ homeData, pageKey }) {

  const myVideo = useRef(null);

  useEffect(() => {
    if (myVideo.current) {
      myVideo.current.muted = true;

      myVideo.current.play();
    }
  }, []);

  const bannerDataKey = `${pageKey}_banner_data`;

  const bannerData = homeData?.data?.data?.[pageKey]?.[bannerDataKey][0];
  const isImage = bannerData?.is_image === "1";
  const isVideo = bannerData?.is_video === "1";

  const videoElement = isVideo && !isImage && (
    <video id="myvid" ref={myVideo} loop autoPlay muted loading="lazy" height={700} width={1920}>
      <source src={bannerData.image_video} type="video/mp4" alt={bannerData.altText} />
      <track kind="captions" label="English" src="" srclang="en" default></track>
    </video>
  );

  const imageElement = isImage && !isVideo && (
    <img src={bannerData.image_video} alt={bannerData.altText} height={650} width={1920} loading="lazy"/>
  );

  return (
    <>
      {videoElement}
      {imageElement}
      {isVideo && (
          <div className="play-wrap">
            <div id="app">
              <div className={`pause`}>
                <div className="line line_1"></div>
                <div className="line line_2"></div>
              </div>
              <div className={`play`}>
                <div className="line line_1"></div>
                <div className="line line_2"></div>
                <div className="line line_3"></div>
              </div>
            </div>
          </div>
        // </span>
      )}
    </>
  );
}
