import React from "react";

import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Contact() {

    const handleClick = () => {
        window.scrollTo(0, 0);
      }

      const currentUrl = window.location.href;
     const cleanedUrl = currentUrl.replace(/\/$/, ''); 
      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://ishantechnologies.com/contact",
        "logo": "https://ishantechnologies.com/api/static/13924ishan_logo_png.png",
        "name": "Ishan Technologies",
        "description": "Whether you have questions, require further information about our IT & Telecom solutions, or wish to discuss a potential collaboration, Ishan Technologies is here to assist you. Call us!",
        "email": "helpdesk@ishantechnologies.com",
        "telephone": "+91 28166-47426",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "B-11, Privilon, Iskcon Cross Road, Ambli - Bopal Rd,",
          "addressLocality": "Ahmedabad",
          "addressCountry": "India",
          "addressRegion": "Gujarat",
          "postalCode": "380054"
        }
      };

    return (
        <>
        <Helmet>
            <link rel="canonical" href={cleanedUrl} />
            <title>Contact Us | Ishan Technologies</title>
            <meta name="description" content="Contact Ishan Technologies: Reach out for inquiries, collaborations, or career opportunities. Connect with us at our Ahmedabad head office. Start the conversation now!" />
        </Helmet>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
            <div className="page_heading">
                <div className="container">
                    <h1>Contact us</h1> <p>UNIQUELY<span>present</span></p>
                </div>
            </div>
            <div className="cms contact spin-wrap">
                <div className="spin-round-wrap">
                    <div className="spin-round color-navy-blue">
                        <span className='ballDot1'>1</span>
                        <span className='ballDot2'>2</span>
                        <span className='ballDot3'>3</span>
                        <span className='ballDot4'>4</span>
                    </div>
                </div>
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li>Support</li>
                            <li>Locate us</li>
                        </ul>
                    </div>
                    <h2>Starting a conversation is really simple</h2>
                    <p className="description">Whether you have questions, require further information about our IT & Telecom solutions, or wish to discuss a potential collaboration, we are here to assist you.</p>
                    <h3>Ahmedabad - Head Office</h3>
                    <p>B-11, Privilon, Iskcon Cross Road, Ambli - Bopal Rd, Ahmedabad, Gujarat 380054</p>
                    <ul className="forms-btns">
                        <li>
                            <Link to="/contact/contact-for-sales" className="action-btn" onClick={handleClick}>Contact for Sales</Link>
                        </li>
                        <li>
                            <Link to="/contact/contact-for-customer-service" className="action-btn" onClick={handleClick}>Contact for Customer Service </Link>
                        </li>
                        <li>
                            <Link to="https://www.linkedin.com/jobs/search/?currentJobId=3733672216&f_C=1175864&geoId=92000000&keywords=Ishan%20Technologies%20&location=Worldwide&origin=JOB_SEARCH_PAGE_JOB_FILTER&refresh=true" target="_blank" className="action-btn">Contact for Careers</Link>
                        </li>
                        <li>
                            <Link to="/contact/partner-with-us" className="action-btn" onClick={handleClick}>Partner with us </Link>
                        </li>
                        <li>
                            <Link to="/contact/media-inquires" className="action-btn" onClick={handleClick}>Media Inquiry</Link>
                        </li>
                    </ul>
                </div>
                <div className="spin-round-wrap">
                    <div className="spin-round">
                        <span className='ballDot1'>1</span>
                        <span className='ballDot2'>2</span>
                        <span className='ballDot3'>3</span>
                        <span className='ballDot4'>4</span>
                    </div>
                </div>
            </div>
        </>
    );
}