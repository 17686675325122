import React from 'react'
import { Link } from "react-router-dom";

const CtaBanner = ({ bannerData = [] }) => {

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className="supercharge">
      <div className="container">
        <h2 className="supercharge-heading">{bannerData[0]?.title} </h2>
        <div className="supercharge-btns">
          {bannerData[0]?.button_title_1 !== "" &&
            <Link to={bannerData[0]?.button_url_1}>
              <button className="getintouch" onClick={handleClick}>{bannerData[0]?.button_title_1}</button>
            </Link>
          }

          {bannerData[0]?.button_title_2 !== "" && (
            <Link to={bannerData[0]?.button_url_2} target="_blank">
              <button className="getintouch" onClick={handleClick}>{bannerData[0]?.button_title_2}</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default CtaBanner