import React, { useState } from "react";
import { Link } from "react-router-dom";
import FooterLogoImage from "../../images/footer-logo.png";
import SocialMedia from "./social-media";

export default function Footer({ homeData }) {
  const [openTabIndex, setOpenTabIndex] = useState(-1);
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  const toggleTab = (index) => {
    if (openTabIndex === index) {
      setOpenTabIndex(-1);
    } else {
      setOpenTabIndex(index);
    }
  };

  const isTabOpen = (index) => openTabIndex === index;

  return (
    <div className="container">
      <div className="f-top">
        {homeData &&
          homeData.data &&
          homeData.data.data &&
          homeData.data.data.footer &&
          homeData.data.data.footer.footer_navigation_data &&
          homeData.data.data.footer.footer_navigation_data.map((navItem, index) => (
            <div className="f-part" key={navItem.id}>
              <span className={isTabOpen(index) ? "f-heding open" : "f-heding"} onClick={() => toggleTab(index)}>{navItem.name}</span>
              <ul className={isTabOpen(index) ? "ul-visible" : ""}>
                {homeData.data.data.footer.footer_sub_navigation_data &&
                  homeData.data.data.footer.footer_sub_navigation_data
                    .filter((subNavItem) => subNavItem.nav_id === navItem.id)
                    .map((subNavItem) => (
                      <li key={subNavItem.id}>
                        <Link to={`${subNavItem.url}`} className="link-action" onClick={handleClick}>
                          {subNavItem.name}
                        </Link>
                      </li>
                    ))}
              </ul>
            </div>
          ))}
      </div>
      <div className="f-botttom">
        <div className="lft-links">
          <div className="f-part">
          {homeData?.data?.data?.footer?.footer_address_data[0]?.address.length > 0 &&
          <>
            <span className="title">Corporate Office Address:</span>
            <address>
            {homeData?.data?.data?.footer?.footer_address_data[0]?.address}
            </address>
            </>
          }
          </div>
          <div className="f-part">
          {homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_email.length > 0 &&
          <>
            <span className="title">Helpdesk email id:</span>
            <a className="link-action email-link" href={`mailto:${homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_email}`}>{homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_email}</a>
            </>
          }
          {homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_number.length > 0 &&
          <>
            <span className="title">Helpdesk Number:</span>
            <a className="link-action" href={`tel:${homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_number}`}>{homeData?.data?.data?.footer?.footer_address_data[0]?.helpdesk_number}</a>
            </>
          }
          </div>
        </div>
        <div className="rgt-logo">
          <SocialMedia homeData={homeData} />
          <Link to="/" className="logo">
            <img src={homeData?.data?.data?.footer?.footer_logo_data[0]?.logo || FooterLogoImage} alt={homeData?.data?.data?.footer?.footer_logo_data[0]?.altText} height={49} width={215} loading="lazy"/>
          </Link>
        </div>
      </div>
    </div>
  );
}