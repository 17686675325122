import React, { useState, useEffect, useCallback } from 'react';
import FormsLinks from "../contacts/forms-links";
import axios from "axios";
import { API_BASE_URL, CRM_URL, SITE_KEY } from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Helmet } from 'react-helmet';

export default function ContactForCustomerService() {

  const [pageName, setPageName] = useState("");
  const currentUrl = window.location.href;
  const cleanedUrl = currentUrl.replace(/\/$/, ''); 

  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {

      }
    );
  }, [])
  useEffect(() => {

    const currentPathname = window.location.pathname;
    const pathWithoutTrailingSlash = currentPathname.endsWith("/")
      ? currentPathname.slice(0, -1)
      : currentPathname;
    const parts = pathWithoutTrailingSlash.split("/");
    const lastPart = parts[parts.length - 1];
    setPageName(lastPart);
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleSubmit = useCallback(async (formdata) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {

        let data = {
          Details: formdata,
          token: token
        };
        axios.post(`${CRM_URL}?cmd=customer_service`, data).then((response) => {

          handleClick();
          setFormSubmitted(true);

          axios.post(`${API_BASE_URL}/api/website/createCustomerService`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      })

  });
  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  const [navigation, setNav] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {

      id: (navigation && navigation.id) || "",
      business_email: (navigation && navigation.business_email) || "",
      name: (navigation && navigation.name) || "",
      comp_name: (navigation && navigation.comp_name) || "",
      phone_no: (navigation && navigation.phone_no) || "",
      designation: (navigation && navigation.designation) || "",
      service: (navigation && navigation.service) || "",
      existing_cust: (navigation && navigation.existing_cust) || "",
      existing_cust_id: (navigation && navigation.existing_cust_id) || "",
      issue_description: (navigation && navigation.issue_description) || "",

    },
    validationSchema: Yup.object({
      business_email: Yup.string()
        .required('Please Enter Business Email')
        .email('Invalid Email Address'),
      name: Yup.string().trim().required("Please Enter Name"),
      comp_name: Yup.string().trim().required("Please Enter Company Name"),
      phone_no: Yup.string()
        .required('Please Enter Phone Number')
        .matches(
          /^\d{10}$/,
          'Phone number must be exactly 10 digits'
        ),
      designation: Yup.string().trim().required("Please Enter Designation"),
      service: Yup.string().required("Please Select Service"),
      issue_description: Yup.string().required("Please Enter Issue Description"),
      existing_cust: Yup.string().required("Please Select Details"),
      existing_cust_id: Yup.string().test({
        name: 'custom',
        test: function(value) {
          const isExistingCust = this.parent.existing_cust === 'Yes';
          if (isExistingCust) {
            return /^\d{6}$/.test(value);
          }
          return true; // Validation passes if existing_cust is not 'Yes'
        },
        message: 'Please Enter Service ID',
      }),
      
      
    
    }),

    onSubmit: (values) => {

      const newNav = {
        business_email: values.business_email,
        name: values.name,
        comp_name: values.comp_name,
        phone_no: values.phone_no,
        designation: values.designation,
        service: values.service,
        existing_cust: values.existing_cust,
        existing_cust_id: values.existing_cust_id,
        issue_description: values.issue_description
      };
      handleSubmit(newNav)


      validation.resetForm();

    },
  });

  return (
    <>
       <Helmet>
            <link rel="canonical" href={cleanedUrl} />
            <title>Contact Ishan Technologies for Customer Service</title>
            <meta name="description" content="Contact Ishan Technologies for Customer Service: Need assistance? Reach out to our customer service team. Get in touch with us now!" />
        </Helmet>

      <div className="page_heading">
        <div className="container">
          <h1>Contact for Customer Service</h1>  <p>UNIQUELY<span>present</span></p>
        </div>
      </div>
      <FormsLinks slug={pageName} />
      <div className="cms contact-fields section-space">
        <div className="container">
          <div className="form-wrapper">
            <Form
              id="createNav"
              className={formSubmitted && ("submitted-success")}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <h2>Contact for Customer Service</h2>

              {formSubmitted ? (
                <>
                  <div className='thanks-msg'>
                    <div className="main-container">
                      <div className="check-container">
                        <div className="check-background">
                          <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <div className="check-shadow"></div>
                      </div>
                      <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
                    </div>
                  </div>
                </>
              ) : (
                <>  <div className='item'>
                  <label htmlFor="business_email">Business Email ID:</label>
                  <Input type="email" id="business_email" name="business_email" onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.business_email || ""}
                    invalid={
                      validation.touched.business_email && validation.errors.business_email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.business_email && validation.errors.business_email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.business_email}
                    </FormFeedback>
                  ) : null}
                </div>
                  <div className='item'>
                    <label htmlFor="name">Name:</label>
                    <Input type="text" id="name" name="name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="comp_name">Company Name:</label>
                    <Input type="text" id="comp_name" name="comp_name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.comp_name || ""}
                      invalid={
                        validation.touched.comp_name && validation.errors.comp_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.comp_name && validation.errors.comp_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.comp_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="phone_no">Phone Number:</label>
                    <Input type="text" id="phone_no" name="phone_no"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_no || ""}
                      invalid={
                        validation.touched.phone_no && validation.errors.phone_no
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone_no && validation.errors.phone_no ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone_no}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="designation">Designation:</label>
                    <Input type="text" id="designation" name="designation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.designation || ""}
                      invalid={
                        validation.touched.designation && validation.errors.designation
                          ? true
                          : false
                      }
                    />
                    {validation.touched.designation && validation.errors.designation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.designation}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label>Please select a service:</label>
                    <div className="rgt">
                      <span>
                        <Input type="radio" name="service" value="Retail Broadband" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service === 'Retail Broadband'} />
                        <span className="label-radio">Retail Broadband</span>
                      </span>
                      <span>
                        <Input type="radio" name="service" value="Enterprise Connectivity" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service === 'Enterprise Connectivity'} />
                        <span className="label-radio">Enterprise Connectivity</span>
                      </span>

                    </div>
                    {validation.touched.service && validation.errors.service ? (
                      <FormFeedback type="invalid">
                        {validation.errors.service}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label>Do you have an existing service ID?</label>
                    <div className="rgt">
                      <span>
                        <Input type="radio" name="existing_cust" value="Yes" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.existing_cust === 'Yes'} />
                        <span className="label-radio">Yes</span>
                      </span>
                      <span>
                        <Input type="radio" name="existing_cust" value="No" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.existing_cust === 'No'} />
                        <span className="label-radio">No</span>
                      </span>
                    </div>
                    {validation.touched.existing_cust && validation.errors.existing_cust ? (
                      <FormFeedback type="invalid">
                        {validation.errors.existing_cust}
                      </FormFeedback>
                    ) : null}
                  </div>


                  {validation.values.existing_cust === 'Yes' && (
                    <div className='item'>
                      <label htmlFor="existing_cust_id">Service ID:</label>
                      <Input
                        type="number"
                        id="existing_cust_id"
                        name="existing_cust_id"
                        onChange={(e) => {
                          // Limit to 6 digits
                          const limitedValue = e.target.value.slice(0, 6);
                          
                          // Call the original handleChange with the limited value
                          validation.handleChange({
                            target: { id: 'existing_cust_id', value: limitedValue },
                          });
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.existing_cust_id || ""}
                        invalid={
                          validation.touched.existing_cust_id && validation.errors.existing_cust_id
                            ? true
                            : false
                        }
                      />
                      {validation.touched.existing_cust_id && validation.errors.existing_cust_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.existing_cust_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}

                  <div className='item'>
                    <label htmlFor="issue_description">Service Issue Description:</label>

                    <textarea
                      name="issue_description"
                      value={validation.values.issue_description}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={`form-control ${validation.touched.issue_description && validation.errors.issue_description ? 'is-invalid' : ''
                        }`}
                    ></textarea>
                    {validation.touched.issue_description && validation.errors.issue_description ? (
                      <div className="invalid-feedback">{validation.errors.issue_description}</div>
                    ) : null}
                  </div>

                  <div className='item submit-item'>
                    <button type="submit" className='button btn-midnight-blue'>Submit</button>
                  </div>
                  {errorMsg &&
                    <div className="form-error-msg onlyform-text-center">
                      <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
                    </div>
                  }
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}					