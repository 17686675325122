import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import CtaBanner from "../../components/CommonSections/CtaBanner";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgCoLocation from "../../assest/images/IshanColocation.png";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function Colocation() {
     useEffect(() => {
          getData();
     }, [])

     const [colocationServicesData, setColocationServicesData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     const getData = () => {
          axios.post(`${API_BASE_URL}/api/colocation/getColocation`).then((response) => {

               setColocationServicesData(response);
console.log(response);

          }).catch((error) => {
               <PageLoader />
          })
     }
     return (

          <>
               {colocationServicesData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data
                              [0].title} url='https://ishantechnologies.com/data-center-services/colocation' parentTitle='Data Centre and Cloud Services' parentUrl='https://ishantechnologies.com/data-center-services' />
                     <MetaTag data={colocationServicesData} pageKey="colocation_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={colocationServicesData} pageKey="colocation_services" />
                              </div>
                         </div>
                         <div className="video ">
                              <FrontVideo homeData={colocationServicesData} pageKey="colocation_services" />
                              <h2 className="home-heading">{colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data
                              [0].banner_title}</h2>
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgCoLocation} alt="Co-location Services" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: colocationServicesData?.data?.data?.colocation_services?.colocation_services_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={colocationServicesData?.data?.data?.colocation_services?.colocation_services_offer_data} />
                         </div>

                         {colocationServicesData?.data?.data?.colocation_services?.colocation_map_data.length > 0 &&
                              <div className="ishan-map sec-ishan-map">
                                   <div className="container">
                                        <div className="indian-map">
                                             {<img src={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.image} alt={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.altText} height={796} width={712} loading="lazy"/>}
                                             <div className="map-title">
                                                  <h4>{colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.title}</h4>
                                             </div>
                                             <div className="map-info">
                                                  {colocationServicesData?.data?.data?.colocation_services?.colocation_map_points_data.map((offer, index) => (
                                                       <div className="map-info-inner">
                                                            <img src={offer.icon} alt={offer.altText} height={50} width={50} loading="lazy"/>
                                                            <h3>{offer.icon_title}</h3>
                                                       </div>
                                                  ))}
                                             </div>
                                        </div>
                                        <div className="indian-places">
                                             {<img src={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.sub_image} alt={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.iconAltText} height={173} width={1128} loading="lazy" />}
                                        </div>
                                   </div>
                              </div>
                         }
                         <CtaBanner bannerData={colocationServicesData?.data?.data?.colocation_services?.colocation_services_cta_banner_data} />
                    </>
               )}
          </>
     );
}