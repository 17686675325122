import React from 'react'
import { Link } from "react-router-dom";

const Advantage = ({ title, data = [] }) => {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     return (
          <div className="advantage section-space spin-wrap">
               <div className="spin-round-wrap">
                    <div className="spin-round color-navy-blue">
                         <span className='ballDot1'>1</span>
                         <span className='ballDot2'>2</span>
                         <span className='ballDot3'>3</span>
                         <span className='ballDot4'>4</span>
                    </div>
               </div>
               <div className="container">
                    <h2>The Ishan Advantage</h2>
                    <div className="advantage-inner">

                         {data.map((advantage, index) => (
                              <div className="advantage-row" key={index}>
                                   <div className="advantage-rowinner">
                                        <img src={advantage.icon} alt={advantage.altText} height={60} width={60} loading="lazy"/>
                                        <h4>{advantage.title}</h4>
                                        <p>{advantage.description}</p>
                                   </div>
                              </div>
                         ))}
                    </div>
                    {title != null &&
                         <Link to={title?.button_url}>
                              <button className="button btn-advantage" onClick={handleClick}>{title?.button_title}</button>
                         </Link>
                    }

               </div>
               <div className="spin-round-wrap">
                    <div className="spin-round">
                         <span className='ballDot1'>1</span>
                         <span className='ballDot2'>2</span>
                         <span className='ballDot3'>3</span>
                         <span className='ballDot4'>4</span>
                    </div>
               </div>
          </div>
     )
}

export default Advantage