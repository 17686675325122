import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconFacebook from "../../assest/svg/icon-facebook";
import IconInstagram from "../../assest/svg/icon-instagram";
import IconLinkdin from "../../assest/svg/icon-linkdin";
import IconTwitter from "../../assest/svg/icon-twitter";
import IconYoutube from "../../assest/svg/icon-youtube";

export default function SocialMedia({ homeData }) {

    const [socialMedia, setSocialMedia] = useState([]);


    useEffect(() => {
        if (homeData && homeData.data && homeData.data.data && homeData.data.data.footer && homeData.data.data.footer.social_navigation_data) {
            const general_setting_data = homeData.data.data.footer.social_navigation_data;

            setSocialMedia(general_setting_data[0]);
        }

    }, [homeData])


    return (
        <div className="social-media">
            <ul>
                {socialMedia?.facebook_url !== "" &&
                    <li>
                        <Link className="link-action" to={socialMedia.facebook_url} target="_blank">
                            Facebook
                            <IconFacebook />
                        </Link>
                    </li>
                }
                {socialMedia?.instagram_url !== "" &&
                    <li>
                        <Link className="link-action" to={socialMedia.instagram_url} target="_blank">
                            Instagram
                            <IconInstagram />
                        </Link>
                    </li>
                }
                {socialMedia?.linkedin_url !== "" &&
                    <li>
                        <Link className="link-action" to={socialMedia.linkedin_url} target="_blank">
                            Linkedin
                            <IconLinkdin />
                        </Link>
                    </li>
                }
                {socialMedia?.twitter_url !== "" &&
                    <li>
                        <Link className="link-action" to={socialMedia.twitter_url} target="_blank">
                            Twitter
                            <IconTwitter />
                        </Link>
                    </li>
                }
                {socialMedia?.youtube_url !== "" &&
                    <li>
                        <Link className="link-action" to={socialMedia.youtube_url} target="_blank">
                            Youtube
                            <IconYoutube />
                        </Link>
                    </li>
                }
            </ul>
        </div>
    );
}