const API_BASE_URL = "https://ishantechnologies.com";
//const API_BASE_URL = "http://localhost:8000";
//const API_BASE_URL = "https://ishan.projectdemo.company";
const CRM_URL = "https://wapbot.ishancloud.com/ishan-web/api.php"
const SITE_KEY = "6LcTtOIoAAAAAAN5IAfi6EfkAnFqWE1tp9wIm2WG"
export {
    API_BASE_URL,
    CRM_URL,
    SITE_KEY
}
