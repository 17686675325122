import React, { useState, useEffect } from 'react';
import FormsLinks from "../contacts/forms-links";
import axios from "axios";
import { API_BASE_URL, CRM_URL, SITE_KEY } from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Helmet } from 'react-helmet';

export default function MediaInquires() {


  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pageName, setPageName] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const currentUrl = window.location.href;
  const cleanedUrl = currentUrl.replace(/\/$/, ''); 

  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {

      }
    );
  }, [])

  useEffect(() => {

    const currentPathname = window.location.pathname;
    const pathWithoutTrailingSlash = currentPathname.endsWith("/")
      ? currentPathname.slice(0, -1)
      : currentPathname;
    const parts = pathWithoutTrailingSlash.split("/");
    const lastPart = parts[parts.length - 1];
    setPageName(lastPart);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  const handleSubmit = (newNav) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {
        let data = {
          Details: newNav,
          token: token
        }
        axios.post(`${CRM_URL}?cmd=media_inquires`, data).then((response) => {

          handleClick();
          setFormSubmitted(true);
          axios.post(`${API_BASE_URL}/api/website/createMediaInquires`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      })
  };

  const [navigation, setNav] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {

      business_email_id: (navigation && navigation.business_email_id) || "",
      name: (navigation && navigation.name) || "",
      company_name: (navigation && navigation.company_name) || "",
      phone_number: (navigation && navigation.phone_number) || "",
      designation: (navigation && navigation.designation) || "",
      partnership_interest_description: (navigation && navigation.partnership_interest_description) || "",


    },
    validationSchema: Yup.object({
      business_email_id: Yup.string()
        .required('Please Enter Business Email')
        .email('Invalid Email Address'),
      name: Yup.string().trim().required("Please Enter Name"),
      company_name: Yup.string().trim().required("Please Enter Company Name"),
      phone_number: Yup.string()
        .required('Please Enter Phone Number')
        .matches(
          /^\d{10}$/,
          'Phone number must be exactly 10 digits'
        ),
      designation: Yup.string().trim().required("Please Enter Designation"),
      partnership_interest_description: Yup.string().trim().required("Please select Description"),

    }),

    onSubmit: (values) => {

      const newNav = {
        business_email_id: values.business_email_id,
        name: values.name,
        company_name: values.company_name,
        phone_number: values.phone_number,
        designation: values.designation,
        partnership_interest_description: values.partnership_interest_description,

      };
      handleSubmit(newNav)


      validation.resetForm();

    },
  });
  return (
    <>
     <Helmet>
            <link rel="canonical" href={cleanedUrl} />
            <title>Contact Ishan Technologies for Media Inquiry</title>
            <meta name="description" content="Contact Ishan Technologies for Media Inquiry: For media inquiries and collaborations, reach out to us. Contact now to discuss potential partnerships!" />
        </Helmet>
      <div className="page_heading">
        <div className="container">
          <h1>Media Inquiry</h1>  <p>UNIQUELY<span>present</span></p>
        </div>
      </div>
      <FormsLinks slug={pageName} />
      <div className="cms contact-fields section-space">
        <div className="container">
          <div className="form-wrapper">
            <Form
              id="createNav"
              className={formSubmitted && ("submitted-success")}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <h2>Media Inquiry</h2>
              {formSubmitted ? (
                <>
                  <div className='thanks-msg'>
                    <div className="main-container">
                      <div className="check-container">
                        <div className="check-background">
                          <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <div className="check-shadow"></div>
                      </div>
                      <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='item'>
                    <label htmlFor="business_email_id">Business Email ID:</label>
                    <Input type="email" id="business_email_id" name="business_email_id" onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.business_email_id || ""}
                      invalid={
                        validation.touched.business_email_id && validation.errors.business_email_id
                          ? true
                          : false
                      }
                    />
                    {validation.touched.business_email_id && validation.errors.business_email_id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.business_email_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="name">Name:</label>
                    <Input type="text" id="name" name="name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="company_name">Company Name:</label>
                    <Input type="text" id="company_name" name="company_name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.company_name || ""}
                      invalid={
                        validation.touched.company_name && validation.errors.company_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.company_name && validation.errors.company_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.company_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="phone_number">Phone Number:</label>
                    <Input type="text" id="phone_number" name="phone_number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_number || ""}
                      invalid={
                        validation.touched.phone_number && validation.errors.phone_number
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone_number && validation.errors.phone_number ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone_number}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="designation">Designation:</label>
                    <Input type="text" id="designation" name="designation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.designation || ""}
                      invalid={
                        validation.touched.designation && validation.errors.designation
                          ? true
                          : false
                      }
                    />
                    {validation.touched.designation && validation.errors.designation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.designation}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="partnership_interest_description">Query Description:</label>

                    <textarea
                      name="partnership_interest_description"
                      value={validation.values.partnership_interest_description}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={`form-control ${validation.touched.partnership_interest_description && validation.errors.partnership_interest_description ? 'is-invalid' : ''
                        }`}
                    ></textarea>
                    {validation.touched.partnership_interest_description && validation.errors.partnership_interest_description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.partnership_interest_description}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item submit-item'>
                    <button type="submit" className='button btn-midnight-blue'>Submit</button>
                  </div>
                  {errorMsg &&
                    <div className="form-error-msg onlyform-text-center">
                      <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
                    </div>
                  }
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}					