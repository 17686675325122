import React, { useEffect, useState } from "react";
import FrontVideo from "../components/homevideo";
import HeaderTitle from "../components/headerTitle";
import { API_BASE_URL } from "../config/config";
import axios from "axios";
import Information from "../components/CommonSections/Information";
import WhatWeOffer from "../components/CommonSections/WhatWeOffer";
import Advantage from "../components/CommonSections/Advantage";
import Partners from "../components/CommonSections/Partners";
import CtaBanner from "../components/CommonSections/CtaBanner";
import PageLoader from "../components/home/pageload";
import ClientExperiences from "../components/home/client-experiences";
import MetaTag from "../components/CommonSections/MetaTag";
import ServiceSchema from "../components/CommonSections/serviceSchema";

export default function SystemsIntegration() {

  const [systemIntegrationData, setSystemIntegrationData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);
  const [partnersData, setPartnersData] = useState(null);

  useEffect(() => {
    axios.post(`${API_BASE_URL}/api/systemintegration/getSystemIntegration`).then((response) => {
      setSystemIntegrationData(response);

      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
      const partnersData = generalSettingsData.find((item) => item.tag === "system_integration_partners") || null;
      const systemIntegrationSettingsData = generalSettingsData.find((item) => item.tag === "service_integration_advantage") || null;
      const systemIntegrationSettingsResponse = JSON.parse(systemIntegrationSettingsData?.response || "{}");
      const partnersResponse = JSON.parse(partnersData?.response || "{}");
      systemIntegrationSettingsResponse.advantages = systemIntegrationSettingsResponse;
      setSettingResponse(systemIntegrationSettingsResponse);
      setPartnersData(partnersResponse);

    }).catch((error) => {
      <PageLoader />
    })
  }, [])

  return (

    <>
      {systemIntegrationData === null ? (
        <PageLoader />
      ) : (
        <>
        <ServiceSchema title={systemIntegrationData?.data?.data?.system_integration?.system_integration_banner_data
            [0].title} url='https://ishantechnologies.com/systems-integration' />
        <MetaTag data={systemIntegrationData} pageKey="system_integration"/>
          <div className="page_heading responsive_heading">
            <div className="container">
              <HeaderTitle headerTitledata={systemIntegrationData} pageKey="system_integration" />

            </div>
          </div>
          <div className="video ">
            <FrontVideo homeData={systemIntegrationData} pageKey="system_integration" />
            <h2 className="home-heading">{systemIntegrationData?.data?.data?.system_integration?.system_integration_banner_data
            [0].banner_title}</h2>
          </div>

          <Information homeData={systemIntegrationData?.data?.data?.system_integration?.system_integration_information_data[0]?.description} />

          <WhatWeOffer title={settingResponse?.what_we_offer} data={systemIntegrationData?.data?.data?.system_integration?.system_integration_offer_data} />

          <Advantage title={settingResponse?.advantages} data={systemIntegrationData?.data?.data?.system_integration?.system_integration_advantages_data} />

          <Partners title={partnersData?.title} data={systemIntegrationData?.data?.data?.system_integration?.partners_data} />

          <div className="client-experiences section-space">
            <ClientExperiences homeData={systemIntegrationData} pageKey="system_integration" tag="system_integration" />
          </div>
          <CtaBanner bannerData={systemIntegrationData?.data?.data?.system_integration?.system_integration_cta_banner_data} />
        </>
      )}

    </>
  );
}