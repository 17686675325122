import React, { useEffect, useState } from "react";
import UpcomingEventsSlick from '../../src/components/slick.js/event-slick';
import FrontVideo from "../components/homevideo";
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import { Link } from "react-router-dom";

function formatDate(dateString) {
     const eventDate = new Date(dateString);
     const month = eventDate.toLocaleString("default", { month: "short" }).toUpperCase();
     const day = eventDate.getDate();
     const dayWithSuffix = getDayWithSuffix(day);
     return { month, dayWithSuffix };
}

function getDayWithSuffix(day) {
     if (day >= 11 && day <= 13) {
          return day + "th";
     }
     switch (day % 10) {
          case 1:
               return day + "st";
          case 2:
               return day + "nd";
          case 3:
               return day + "rd";
          default:
               return day + "th";
     }
}


export default function Events() {

     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [eventsData, setEventsData] = useState(null);

     useEffect(() => {

          axios.post(`${API_BASE_URL}/api/events/getAllEvents`).then((response) => {

               setEventsData(response);

          }).catch((error) => {
               <PageLoader />
          })
     }, [])

     const filterEvents = (events, filterDate, isUpcoming) => {
          return events.filter((event) => {
               const eventDate = new Date(event.date);
               if (isUpcoming) {
                    return eventDate >= filterDate;
               } else {
                    return eventDate < filterDate;
               }
          });
     };

     const today = new Date();
     today.setHours(0, 0, 0, 0); // Set the time to midnight

     const upcomingEvents = filterEvents(eventsData?.data?.data?.events?.event || [], today, true);
     const previousEvents = filterEvents(eventsData?.data?.data?.events?.event || [], today, false);

          
     return (
          <>
          {eventsData &&
          <>
          <MetaTag data={eventsData} pageKey="events"/>
               {/* <div className="page_heading responsive_heading">
                    <div className="container">
                         <h1>{settingResponse?.title}</h1><p>{settingResponse?.sub_title}<span>{settingResponse?.italic}</span></p>
                    </div>
               </div>
               <div className="event-banner banner-bg">
                    <div className="container">
                         <div className="banner-bg-inner">
                              <h3>Explore technology as<br></br>a catalyst to innovation</h3>
                              <h5>Join us at the</h5>
                              {<img src={ImgBFSI} alt="Broadband" loading="lazy"/>}
                              <h5>Date: 3rd August 2023 | Thursday<br></br> Location: Mumbai, Maharashtra</h5>
                         </div>
                    </div>
               </div> */}
                  <div className="page_heading">
            <div className="container">
              <h1>{eventsData?.data?.data?.events?.events_banner_data[0]?.title}</h1><p>{eventsData?.data?.data?.events?.events_banner_data[0]?.sub_title}<span>{eventsData?.data?.data?.events?.events_banner_data[0]?.italic}</span></p>
            </div>
          </div>

          {/* Banner Video/Image START */}
          <div className="video video-event">
            <FrontVideo homeData={eventsData} pageKey="events" />
          </div>
          {/* Banner Video/Image END */}

               <div className="upcoming-event section-space spin-wrap">
                    <div className="spin-round-wrap">
                         <div className="spin-round color-navy-blue">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>

                    <div className="container">
                         <div className="event-mrgn-btm">
                              <h2 className="event-heading">Upcoming Events</h2>
                              <div className="event-inner-sec">

                                   {upcomingEvents.map((event) => (
                                        <div className="event-inner" key={event.id}>

                                             {<img src={event.image} alt={event.altText} loading="lazy"/>}
                                             <div className="event-dtls">
                                                  <div className="event-left">
                                                       <h3 className="">{formatDate(event.date).month}</h3>
                                                       <h3 className="">{formatDate(event.date).dayWithSuffix}</h3>
                                                  </div>
                                                  <div className="event-right">
                                                       <p>{event.title}</p>
                                                       <div className="btn-logo">
                                                            <Link to={`/events${event?.button_url}`}>
                                                                 <button className="button btn-midnight-blue" onClick={handleClick}>Know more</button>
                                                            </Link>
                                                            {event.promotion_image !== `${API_BASE_URL}/api/static/null` && (
                                                                 <img src={event.promotion_image} alt={event.altText} loading="lazy"/>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>
                         </div>

                         <h2 className="event-heading">Previous Events</h2>
                         <div className="previous-inner-sec">
                              <div className="previous-content">
                                   <UpcomingEventsSlick data={previousEvents} />
                              </div>
                         </div>
                    </div>

                    <div className="spin-round-wrap">
                         <div className="spin-round">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
               </div>
               </>
               }
          </>
     );
} 