import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import { API_BASE_URL } from "../../config/config";
import axios from "axios";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import Partners from "../../components/CommonSections/Partners";
import PageLoader from "../../components/home/pageload";
import ClientExperiences from "../../components/home/client-experiences";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgDataLifeCycle from "../../assest/images/IshanDataLifeCycle.png";
import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function DataLifeCycleSolutions() {

     const [lifeCycleSolutionData, setLifeCycleSolutionData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const [partnersData, setPartnersData] = useState(null);

     useEffect(() => {
          const requestData = {
               "service_tag": "data_lifycycle_solution"
          };

          axios.post(`${API_BASE_URL}/api/datalifecyclesolutions/getDataLifecycleSolutions`, requestData).then((response) => {

               setLifeCycleSolutionData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const partnersData = generalSettingsData.find((item) => item.tag === "data_lifycycle_solution_partners") || null;
               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "data_lifycycle_solution") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");
               const partnersResponse = JSON.parse(partnersData?.response || "{}");

               setSettingResponse(networkServicesSettingsResponse);
               setPartnersData(partnersResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }, [])

     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     return (

          <>
               {lifeCycleSolutionData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/data-lifecycle-solutions' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={lifeCycleSolutionData} pageKey="data_lifecycle_solutions"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={lifeCycleSolutionData} pageKey="data_lifecycle_solutions" />
                              </div>
                         </div>
                         <div className="video ">
                              <FrontVideo homeData={lifeCycleSolutionData} pageKey="data_lifecycle_solutions" />
                              <h2 className="home-heading">{lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_banner_data
                              [0].banner_title}</h2>
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgDataLifeCycle} alt="Data Life Cycle" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.partners} data={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_advantages_data} />

                         <Partners title={partnersData?.title} data={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.partners_data} />

                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={lifeCycleSolutionData} pageKey="data_lifecycle_solutions" tag="data_lifycycle_solution" />
                         </div>
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading">{lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.title}</h2>
                                   <div className="supercharge-btns">
                                        {lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                        {lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{lifeCycleSolutionData?.data?.data?.data_lifecycle_solutions?.data_lifecycle_solutions_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>

                              </div>
                         </div>
                    </>
               )}
          </>

     );
}