import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import DownloadIcon from "../assest/images/download-icon.png";
import { Link } from "react-router-dom";

export default function DownloadsResources() {
     useEffect(() => {
          getData();
     }, [])
     const [downloadData, setDownloadData] = useState(null);
     const [metaData, setMetadata] = useState(null);
     const [bannerTitle, setBannerTitle] = useState(null);
     const getData = () => {
          axios.post(`${API_BASE_URL}/api/download/getAlldownload`).then((response) => {
               if (response.data.error === false) {
                    setDownloadData(response.data.data);
                    setMetadata(response);
                    const bannerTitleResponse = JSON.parse(response.data.data?.download?.banner_data[0]?.response || "{}");
                    setBannerTitle(bannerTitleResponse);
               }
          }).catch((error) => {
               <PageLoader />
          })
     }

     return (
          <>
          {downloadData !== null &&
          <>
               <MetaTag data={metaData} pageKey="download"/>
               <div className="page_heading responsive_heading">
                    <div className="container">
                         <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
                    </div>
               </div>
               <div className="downloadsresources md-section-space">
                    <div className="container">
                         <div className="dwnld">
                              <ul>
                                   {downloadData?.download?.download_data.map((tag, index) => (
                                        <li>
                                             {<img src={tag.image} alt={tag.altText} height={375} width={275} loading="lazy"/>}
                                             <div className="dwnld-details">
                                                  <Link to={tag.pdfFile} target="_blank">
                                                       {tag.name} {<img src={DownloadIcon} alt="icon" loading="lazy"/>}</Link>
                                             </div>
                                        </li>
                                   ))}

                              </ul>
                         </div>
                    </div>
               </div>
          </>
          }
          </>
     );
}