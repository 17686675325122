import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

const Navigation = ({ homeData, isOpen, toggleMenu }) => {
  const [headerMegaMenu, setHeaderMegaMenu] = useState([]);
  const [isFullwidthVisible, setIsFullwidthVisible] = useState(false);
  const [isFullwidthVisibleSubMenu, setIsFullwidthVisibleSubMenu] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [activeMenuIndexSubMenu, setActiveMenuIndexSubMenu] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  useEffect(() => {
    if (homeData && homeData.data && homeData.data.data && homeData.data.data?.header && homeData.data.data?.header?.header_mega_menu_data) {
      const headerDataInfo = homeData.data.data?.header?.header_mega_menu_data;
      setHeaderMegaMenu(headerDataInfo);
    } else {
      setHeaderMegaMenu([]);
    }
  }, [homeData])

  const toggleFullwidth = (index) => {
    if (index === activeMenuIndex) {
      setIsFullwidthVisible(!isFullwidthVisible);
    } else {
      setIsFullwidthVisible(true);
      setActiveMenuIndex(index);
    }
    setIsFullwidthVisibleSubMenu(!isFullwidthVisibleSubMenu);
    setActiveMenuIndexSubMenu(null);
  };

  const toggleFullwidthSubMenu = (index) => {
    if (index === activeMenuIndexSubMenu) {
      setIsFullwidthVisibleSubMenu(!isFullwidthVisibleSubMenu);
      setActiveMenuIndexSubMenu(null);
    } else {
      setIsFullwidthVisibleSubMenu(true);
      setActiveMenuIndexSubMenu(index);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        setIsFullwidthVisible(true);
        setIsFullwidthVisibleSubMenu(true);
      } else {
        setIsFullwidthVisible(false);
        setIsFullwidthVisibleSubMenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    toggleMenu();
  };
  return (

    <>
      {isMobile ? (
        <nav>
          <span className="menu-text">Menu</span>
          {headerMegaMenu.length > 0 &&
            <ul className="navbar-level-1">
              {headerMegaMenu.map((headerMenu, index) => (
                <li className={`sub-here ${index === activeMenuIndex && isFullwidthVisible ? 'open' : ''}`}

                  key={index}>

                  <Link className="link-action" to={headerMenu?.url} onClick={handleToggle}>{headerMenu?.name}</Link>
                  <span className="redbox" onClick={() => toggleFullwidth(index)}></span>
                  {isFullwidthVisible && index === activeMenuIndex && (
                    <div className='fullwidth'>
                      <div className='container'>
                        {headerMenu.header_sub_navigation.length > 0 &&
                          <ul className="navbar-level-2">
                            {headerMenu.header_sub_navigation.map((subMenu, indexData) => (
                              <li className={`sub-here subNav ${indexData === activeMenuIndexSubMenu && isFullwidthVisibleSubMenu ? 'open' : ''}`} key={indexData}>
                                <Link className="link-action" to={subMenu?.url} onClick={handleToggle}>{subMenu?.name}</Link>
                                {subMenu.header_third_level_navigation.length > 0 &&
                                  <>
                                    <span className="bluebox" onClick={() => toggleFullwidthSubMenu(indexData)}></span>
                                    {isFullwidthVisibleSubMenu && indexData === activeMenuIndexSubMenu && (

                                      <ul className="navbar-level-3">

                                        {subMenu.header_third_level_navigation.map((thirdMenu) => (
                                          <>
                                            <li><Link className="link-action" to={thirdMenu?.url} onClick={handleToggle}>{thirdMenu?.name}</Link></li>


                                            {thirdMenu.header_forth_level_navigation.length > 0 && thirdMenu.header_forth_level_navigation.map((forthMenu) => (
                                              <li><Link className="link-action" to={forthMenu?.url} onClick={handleToggle}>{forthMenu?.name}</Link></li>
                                            ))}
                                          </>
                                        ))}

                                      </ul>
                                    )}
                                  </>
                                }

                              </li>
                            ))}
                          </ul>
                        }
                      </div>
                    </div>
                  )}
                </li>
              ))}

              <li className="separator">
                <Link to="https://payment.ishantechnologies.com/gateway/pay.php" className="link-action">
                  Pay Now
                </Link>
              </li>
            </ul>
          }
        </nav>) : (

        <nav className='navbar-fixed-top'>
          <span className="menu-text">Menu</span>
          {headerMegaMenu.length > 0 &&
            <ul className="nav-level-1">
              {headerMegaMenu.map((headerMenu, index) => (
                <li className="sub-here">

                  <Link className="link-action" to={headerMenu?.url}>{headerMenu?.name}</Link>

                  {headerMenu.header_sub_navigation.length > 0 &&
                    <ul className="nav-level-2">
                      {headerMenu.header_sub_navigation.map((subMenu, indexData) => (
                        <li className={`sub-here subNav ${subMenu.header_third_level_navigation.length > 0 ? 'has-sub-nav' : ''}`}>
                          <Link className="link-action" to={subMenu?.url}>{subMenu?.name}</Link>
                          {subMenu.header_third_level_navigation.length > 0 &&
                            <>
                              <ul className="nav-level-3">

                                {subMenu.header_third_level_navigation.map((thirdMenu) => (
                                  <>
                                    <li><Link className="link-action" to={thirdMenu?.url}>{thirdMenu?.name}</Link></li>

                                    {thirdMenu.header_forth_level_navigation.length > 0 && thirdMenu.header_forth_level_navigation.map((forthMenu) => (
                                      <li><Link className="link-action" to={forthMenu?.url}>{forthMenu?.name}</Link></li>
                                    ))}
                                  </>
                                ))}

                              </ul>

                            </>
                          }

                        </li>
                      ))}
                    </ul>
                  }

                </li>
              ))}

              <li className="separator">
                <Link to="https://payment.ishantechnologies.com/gateway/pay.php" target="_blank" className="link-action">
                  Pay Now
                </Link>
              </li>

            </ul>
          }
        </nav>

      )}
    </>

  );
};
export default Navigation;