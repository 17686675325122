import React, { useEffect, useState } from "react";
import FrontVideo from "../components/homevideo";
import Fostering from "../components/home/fostering";
import Services from "../components/home/services";
import Blogs from "../components/home/blogs";
import ClientExperiences from "../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function Home() {
  // Hero Slider START
  var heroSettings = {
    infinite: true,
    arrows: true,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    draggable: true,
    dots: false,
    speed: 900,
    swipe: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  // Hero Slider END

  const [homeData, setHomeData] = useState(null);
  useEffect(() => {
    const requestData = {
      "service_tag": "home"
    };
    axios.post(`${API_BASE_URL}/api/home/getHome`, requestData).then((response) => {
      // setTimeout(() => {
      setHomeData(response);
      // }, 1500);
    }).catch((error) => {
      <PageLoader />
    })
  }, [])

//   const script = `
//   {
//     "@context": "https://schema.org",
//     "@type": "Organization",
//     "name": "Ishan Technologies",
//     "url": "https://ishantechnologies.com/",
//     "logo": "https://ishantechnologies.com/api/static/15755ishan_uniquely_c_logo__305_pix_png.png",
//     "contactPoint": {
//       "@type": "ContactPoint",
//       "telephone": "+91 28166-47426",
//       "contactType": "customer service",
//       "areaServed": "IN"
//     },
//     "sameAs": [
//       "https://www.facebook.com/ishanitech",
//       "https://www.instagram.com/ishantechnologies/",
//       "https://www.linkedin.com/company/1175864/",
//       "https://twitter.com/ishanitech",
//       "https://www.youtube.com/@ishantechnologies"
//     ]
//   }
// `;

const schemaData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "url": "https://ishantechnologies.com/",
  "sameAs": [
    "https://www.facebook.com/ishanitech",
    "https://twitter.com/ishanitech",
    "https://www.instagram.com/ishantechnologies/",
    "https://www.youtube.com/@ishantechnologies",
    "https://www.linkedin.com/company/1175864/"
  ],
  "logo": "https://ishantechnologies.com/api/static/13924ishan_logo_png.png",
  "name": "Ishan Technologies",
  "description": "Ishan Technologies's managed ICT services & solutions keep your business running smoothly. Explore our professional managed services, & custom solutions. Contact us now!",
  "email": "helpdesk@ishantechnologies.com",
  "telephone": "+91 28166-47426",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "B-11, Privilon, Iskcon Cross Road, Ambli - Bopal Rd,",
    "addressLocality": "Ahmedabad",
    "addressCountry": "India",
    "addressRegion": "Gujarat",
    "postalCode": "380054"
  }
};
  return (
 
    <>
      {homeData === null ? (
        <PageLoader />
      ) : (
        <>
        {/* <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: script }} /> */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
        <MetaTag data={homeData} pageKey="home"/>
          {/* Hero Banner START */}
          <div className="video play-btn-remove">
            <FrontVideo homeData={homeData} pageKey="home" />
            {/* <h1 className="home-heading">{homeData?.data?.data?.home?.home_banner_data[0].title}</h1> */}
            <div className="hero-content">
              <Slider {...heroSettings}>
                {homeData.data.data?.home?.home_banner_desc_data?.length > 0 && homeData.data.data?.home?.home_banner_desc_data.map((valTitle) => (
                  <div className="slider-item" key={valTitle?.title}>
                    {valTitle?.title &&
                      <span className="home-heading">{valTitle?.title}</span>
                    }
                    {valTitle?.description &&
                      <p>{valTitle?.description}</p>
                    }
                    {valTitle?.button_title &&
                      <Link to={valTitle?.button_url} className="button hero-btn">
                        {valTitle?.button_title}
                      </Link>
                    }
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          {/* Hero Banner END */}
          <div className="fostering home-fostering section-space spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
            <Fostering homeData={homeData} />
            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>
          <Services homeData={homeData} />
          {homeData.data.data?.home?.blogs_info_data.length > 0 &&
            <div className="blogs section-space">
              <Blogs homeData={homeData} pageKey="home" />
            </div>
          }
          <div className="client-experiences section-space">
            <ClientExperiences homeData={homeData} pageKey="home" tag="home" />
          </div>
        </>
      )}
    </>
  );
}  